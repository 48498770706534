const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_SOCKET': {
		const { payload } = action;
		return { ...state, socket: payload };
	}

	case 'SET_SOCKET_MONITOR': {
		const { payload } = action;
		return { ...state, monitor: payload };
	}

	case 'SET_SOCKET_ID': {
		const { payload } = action;
		return { ...state, socketId: payload };
	}

	case 'SET_LAST_MESSAGE': {
		const { payload } = action;
		return { ...state, lastMessage: { ...state.lastMessage, [payload.channel_id]: payload } };
	}

	default:
		return state;
	}
};

export default reducer;
